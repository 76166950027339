import { Context } from '@nuxt/types'
import { NotFoundError } from '~/errors'
import { liffInit } from '~/utils/liff'
import { logger } from '~/utils/logger'

let liffInitialized: boolean = false
const authenticate = async ({ app }: Context) => {
  if (!liffInitialized) {
    await liffInit(process.env.FE_MAIN_LIFF_ID!)
    liffInitialized = true
  }

  // ログインチェック
  const isLoggedIn = app.$accessor.auth.loggedIn
  const isNearExpiration = app.$accessor.auth.nearExpiration
  if (isLoggedIn && !isNearExpiration) {
    logger.debug('ログイン済み')
    return
  }

  logger.info('LINE認証でログイン')
  await app.$accessor.auth.login()
}

const requiresBps2Integration = async ({ app, route }: Context) => {
  logger.info('BPS2連携のチェック')
  // 作業受付・見積など未連携でもアクセス可能なページは対象外
  const ignorePathRegexps = [
    '/',
    '/receipt/.*',
    '/quotations/.*',
    '/mypage/quotations/.*', // 見積履歴はBPS2未連携でも使用可能
    '/out-of-area/', // エリア対象外は閲覧可能
    '/under-construction/', // 公開準備中のページは閲覧可能
    '/pdf/riyoukitei.pdf/',
    '/pdf/riyougaido.pdf/',
    '/unfollow-account/', // フレンドじゃない場合のエラーページは閲覧可能に
    '/location/',
    '/integrate-user-error/',
    '/pit-reservation/.*',
  ]
  if (ignorePathRegexps.some((regexp) => route.path.match(`^${regexp}$`))) {
    logger.info('BPS2連携のチェック - ignore path: path = ', route.path)
    return
  }

  try {
    await app.$accessor.user.user()
    logger.info('BPS2連携済み')
  } catch (err: any) {
    logger.info('BPS2連携のチェック - 顧客情報の取得に失敗', err)
    location.href = '/not-authorized/'
  }
}

const isMapFile = (path: string) => {
  return path.endsWith('.map')
}

export default async function (context: Context) {
  logger.info('middleware: path = ', context.route.fullPath)

  // source mapにアクセスさせない
  if (isMapFile(context.route.fullPath))
    throw new NotFoundError('存在しないページです')

  // 作業受付のリダイレクト処理は全ての処理をスキップ
  const ignorePathRegexps = [
    '/inflow.*',
    '/receipt/redirect.*',
    '/privacy-policy.*',
    '/terms.*',
    '/contact.*',
    '/not-authorized/',
  ]
  if (
    ignorePathRegexps.some((regexp) => context.route.path.match(`^${regexp}$`))
  ) {
    logger.info('auth middleware - ignore path: path = ', context.route.path)
    return
  }

  // LIFF認証
  await authenticate(context)

  // BPS2連携のチェック
  await requiresBps2Integration(context)
}
