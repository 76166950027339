import { actionTree, getterTree, mutationTree } from 'typed-vuex'
import { logger } from '~/utils/logger'

// DeliveryEnqueteParamsの型定義
export type DeliveryEnqueteParams = {
  answerText: string
}

const defaultState = () => {
  return {
    _enquete: {
      answerText: '',
    } as DeliveryEnqueteParams,
  }
}

export const state = () => defaultState()

export const getters = getterTree(state, {
  enquete: (state) => state._enquete,
})

export const mutations = mutationTree(state, {
  setEnquete(state, val: DeliveryEnqueteParams) {
    logger.info('アンケート情報を保存', val)
    state._enquete = val
  },
  clear(state) {
    Object.assign(state, defaultState())
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    saveEnquete({ commit }, enquete: DeliveryEnqueteParams) {
      commit('setEnquete', enquete)
    },
  }
)

export type RootState = ReturnType<typeof state>
