import { onMounted, onUnmounted, ref } from '@nuxtjs/composition-api'

export default function useWindowResize() {
  const height = ref<number>(0)
  const width = ref<number>(0)

  function resize() {
    height.value = window.outerHeight
    width.value = window.outerWidth
  }

  onMounted(() => {
    resize()
    window.addEventListener('resize', resize)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', resize)
  })

  return { height, width, resize }
}
