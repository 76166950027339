import { actionTree, getterTree, mutationTree } from 'typed-vuex'
import {
  CreateEnqueteAnswerInput,
  RegisterUserInput,
  AnswerInput,
} from './../graphql/generated/types'
type UserStateProps = {
  _user: RegisterUserInput
}

type CustomerEnquete = {
  _userEnquete: CreateEnqueteAnswerInput
}

type CustomerInput = UserStateProps & CustomerEnquete
const defaultState = (): CustomerInput => {
  return {
    _user: {
      // 顧客区分
      customerKind: 0,
      // 会社名
      corpName: '',
      // 会社名（カナ）
      corpNameKana: '',
      // 顧客名（姓）
      lastName: '',
      // 顧客名（名）
      firstName: '',
      // 顧客名（姓カナ）
      lastNameKana: '',
      // 顧客名（名カナ）
      firstNameKana: '',
      // 郵便番号
      postCode: '',
      // 都道府県名
      prefectureName: '',
      // 市区町村名
      cityName: '',
      // 地域名
      areaName: '',
      // 建物名
      buildingName: '',
      // 電話番号
      tel: '',
      // メールアドレス
      mailAddress: '',
      // 生年月日
      birthday: '',
      // 性別
      sex: 0,
      // 来店店舗
      shopId: 0,
    },
    _userEnquete: {
      enqueteType: 'receiption',
      answers: [
        // 来訪歴
        {
          questionCode: 'raihoreki',
          answerValues: [{ no: '', text: '' }], // はじめて,過去にあり
        },
        // タイヤに求めること
        {
          questionCode: 'taiya_motomerukoto',
          answerValues: [{ no: '', text: '' }], // はじめて, // 安さ,長持ち,低燃費,メーカー,その他
        },
        // 何を見てご来店されましたか？
        {
          questionCode: 'taiya_konyukento',
          answerValues: [{ no: '', text: '' }], // はじめて, // ホームページ,Web広告,SNS,新聞広告,チラシ,TVCM,ラジオ,情報紙,紹介,店頭看板,その他
        },
        // タイヤ交換のタイミングは？
        {
          questionCode: 'taiya_kokantaimingu',
          answerValues: [{ no: '', text: '' }], // はじめて, // 車検のタイミングで,残溝が少なくなったら,特に決まっていない（気づいたら）,その他
        },
        // 年間走行距離
        {
          questionCode: 'nenkan_sokokyori',
          answerValues: [{ no: '', text: '' }], // 5,000km, 10,000km, 15,000km, 20,000km, 25,000km, 30,000km, それ以上
        },
      ],
    },
  }
}

export const state = () => defaultState()

export const getters = getterTree(state, {
  user: (state) => state._user,
  userEnquete: (state) => state._userEnquete,
})

export const mutations = mutationTree(state, {
  clear(state) {
    Object.assign(state, defaultState())
  },
  setUser(state, inputs: RegisterUserInput) {
    state._user = inputs
  },
  setUserEnquete(state, inputs: Array<AnswerInput>) {
    state._userEnquete = {
      ...state._userEnquete,
      answers: inputs,
    }
  },
})

export const actions = actionTree({ state, getters, mutations }, {})

export type RootState = ReturnType<typeof state>
