// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text-black_5lUVx[data-v-0d1fe359]{color:rgba(0,0,0,.871)}.text-white_13Vn\\+[data-v-0d1fe359]{color:#fff}.text-red_kjd4N[data-v-0d1fe359]{color:#c43}.text-blue_Np3Lf[data-v-0d1fe359]{color:#4587c1}.color-red_B\\+JjA[data-v-0d1fe359]{background:#c43}.color-blue-grey_LvGFp[data-v-0d1fe359]{background:#54617a}.color-white_I2EMo[data-v-0d1fe359]{background:#fff}.pa-1-5_pZfS9[data-v-0d1fe359]{padding:6px}.mt-20_kSOll[data-v-0d1fe359]{margin-top:80px}.mt-24_JfJPC[data-v-0d1fe359]{margin-top:96px}p[data-v-0d1fe359]{margin:0;padding:0}a[data-v-0d1fe359]{-webkit-text-decoration:none;text-decoration:none}.v-text-field--outlined_ogSmn fieldset[data-v-0d1fe359],.v-text-field--outlined_ogSmn.v-input--is-focused_\\+rJ4g fieldset[data-v-0d1fe359]{border-width:0}.v-text-field--outlined_ogSmn.v-input--has-state_eyEUS fieldset[data-v-0d1fe359]{border:2px solid red!important}.v-input__slot_KM5b6[data-v-0d1fe359]{min-height:auto!important;padding:0 12px!important}.v-input_ZN3qt[data-v-0d1fe359]{caret-color:#1867c0!important;font-size:14px!important}.v-input__append-inner_hon1J[data-v-0d1fe359]{margin-top:4px!important}.v-slide-group__next_OHUvI[data-v-0d1fe359],.v-slide-group__prev_qj0Wh[data-v-0d1fe359]{flex:0 1!important;min-width:0!important}.v-expansion-panel-header_ZCUhi[data-v-0d1fe359]{min-height:40px!important}.v-btn_fI-Ig[data-v-0d1fe359]{min-width:auto!important}.v-tab_LIf8F[data-v-0d1fe359]{padding:0 12px!important}.v-item-group_clnOs.v-bottom-navigation_3jx93 .v-btn_fI-Ig.v-size--default_90VbH[data-v-0d1fe359]{height:inherit}.theme--light_bILEx.v-bottom-navigation_3jx93[data-v-0d1fe359]{background-color:#eee!important}.v-bottom-navigation_3jx93[data-v-0d1fe359]{height:auto!important}.v-bottom-navigation_3jx93 .v-btn_fI-Ig[data-v-0d1fe359]{margin-bottom:env(safe-area-inset-bottom)}.button_7mogW[data-v-0d1fe359]{padding:12px 12px 34px!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text-black": "text-black_5lUVx",
	"text-white": "text-white_13Vn+",
	"text-red": "text-red_kjd4N",
	"text-blue": "text-blue_Np3Lf",
	"color-red": "color-red_B+JjA",
	"color-blue-grey": "color-blue-grey_LvGFp",
	"color-white": "color-white_I2EMo",
	"pa-1-5": "pa-1-5_pZfS9",
	"mt-20": "mt-20_kSOll",
	"mt-24": "mt-24_JfJPC",
	"v-text-field--outlined": "v-text-field--outlined_ogSmn",
	"v-input--is-focused": "v-input--is-focused_+rJ4g",
	"v-input--has-state": "v-input--has-state_eyEUS",
	"v-input__slot": "v-input__slot_KM5b6",
	"v-input": "v-input_ZN3qt",
	"v-input__append-inner": "v-input__append-inner_hon1J",
	"v-slide-group__next": "v-slide-group__next_OHUvI",
	"v-slide-group__prev": "v-slide-group__prev_qj0Wh",
	"v-expansion-panel-header": "v-expansion-panel-header_ZCUhi",
	"v-btn": "v-btn_fI-Ig",
	"v-tab": "v-tab_LIf8F",
	"v-item-group": "v-item-group_clnOs",
	"v-bottom-navigation": "v-bottom-navigation_3jx93",
	"v-size--default": "v-size--default_90VbH",
	"theme--light": "theme--light_bILEx",
	"button": "button_7mogW"
};
module.exports = ___CSS_LOADER_EXPORT___;
