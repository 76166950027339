import { defineNuxtPlugin } from '@nuxtjs/composition-api'

export default defineNuxtPlugin(({ store }, inject) => {
  inject('notifier', {
    showSuccessMessage({ content = '' }) {
      store.commit('snackbar/showSuccessMessage', { content })
    },
    showErrorMessage({ content = '' }) {
      store.commit('snackbar/showErrorMessage', { content })
    },
  })
})
