// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text-black_W2SM6{color:rgba(0,0,0,.871)}.text-white_-JWXa{color:#fff}.text-red_seAyn{color:#c43}.text-blue_v4LAL{color:#4587c1}.color-red_woly6{background:#c43}.color-blue-grey_Xncri{background:#54617a}.color-white_OM8p9{background:#fff}.pa-1-5_QCxYB{padding:6px}.mt-20_MbiKR{margin-top:80px}.mt-24_FT7uw{margin-top:96px}p{margin:0;padding:0}a{-webkit-text-decoration:none;text-decoration:none}.v-text-field--outlined_GEVb2 fieldset,.v-text-field--outlined_GEVb2.v-input--is-focused_L3RUD fieldset{border-width:0}.v-text-field--outlined_GEVb2.v-input--has-state_Qae0M fieldset{border:2px solid red!important}.v-input__slot_ldPp\\+{min-height:auto!important;padding:0 12px!important}.v-input_GnIXY{caret-color:#1867c0!important;font-size:14px!important}.v-input__append-inner_ImcTw{margin-top:4px!important}.v-slide-group__next_SfK\\+y,.v-slide-group__prev_5Zc3T{flex:0 1!important;min-width:0!important}.v-expansion-panel-header_OjbA0{min-height:40px!important}.v-btn_GdmUS{min-width:auto!important}.v-tab_M8AeR{padding:0 12px!important}.v-item-group_rI7BT.v-bottom-navigation_ReR7O .v-btn_GdmUS.v-size--default_8MblC{height:inherit}.theme--light_MERZZ.v-bottom-navigation_ReR7O{background-color:#eee!important}.v-bottom-navigation_ReR7O{height:auto!important}.v-bottom-navigation_ReR7O .v-btn_GdmUS{margin-bottom:env(safe-area-inset-bottom)}.bl_description_NMLb1{font-size:16px;line-height:1.7;margin-top:40px;white-space:pre-wrap}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text-black": "text-black_W2SM6",
	"text-white": "text-white_-JWXa",
	"text-red": "text-red_seAyn",
	"text-blue": "text-blue_v4LAL",
	"color-red": "color-red_woly6",
	"color-blue-grey": "color-blue-grey_Xncri",
	"color-white": "color-white_OM8p9",
	"pa-1-5": "pa-1-5_QCxYB",
	"mt-20": "mt-20_MbiKR",
	"mt-24": "mt-24_FT7uw",
	"v-text-field--outlined": "v-text-field--outlined_GEVb2",
	"v-input--is-focused": "v-input--is-focused_L3RUD",
	"v-input--has-state": "v-input--has-state_Qae0M",
	"v-input__slot": "v-input__slot_ldPp+",
	"v-input": "v-input_GnIXY",
	"v-input__append-inner": "v-input__append-inner_ImcTw",
	"v-slide-group__next": "v-slide-group__next_SfK+y",
	"v-slide-group__prev": "v-slide-group__prev_5Zc3T",
	"v-expansion-panel-header": "v-expansion-panel-header_OjbA0",
	"v-btn": "v-btn_GdmUS",
	"v-tab": "v-tab_M8AeR",
	"v-item-group": "v-item-group_rI7BT",
	"v-bottom-navigation": "v-bottom-navigation_ReR7O",
	"v-size--default": "v-size--default_8MblC",
	"theme--light": "theme--light_MERZZ",
	"bl_description": "bl_description_NMLb1"
};
module.exports = ___CSS_LOADER_EXPORT___;
