import { actionTree, getterTree, mutationTree } from 'typed-vuex'
import { getClient } from '~/graphql/client'
import {
  TireMakerAttributesFragment,
  TireSizesInput,
  TiresInput,
  VehiclePeriodsInput,
} from '~/graphql/generated/types'
import { logger } from '~/utils/logger'

const defaultState = () => {
  return {
    _makers: [] as TireMakerAttributesFragment[],
  }
}

export const state = () => defaultState()

export const getters = getterTree(state, {
  makers: (state) => state._makers,
})

export const mutations = mutationTree(state, {
  setMakers(state, val: TireMakerAttributesFragment[]) {
    state._makers = val
  },
  clear(state) {
    Object.assign(state, defaultState())
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    // タイヤ検索	Query	tires
    async tires(_, input: TiresInput) {
      logger.info('タイヤ検索', { input })

      return await getClient()
        .tires({
          input,
        })
        .then((data) => data.tires)
    },
    // タイヤメーカー一覧	Query	tireMakers
    async tireMakers({ getters, commit }) {
      if (getters.makers.length !== 0) {
        logger.debug('タイヤメーカー一覧のキャッシュを使用')
        return getters.makers
      }

      logger.info('タイヤメーカー一覧を取得')
      const makers = await getClient()
        .tireMakers()
        .then((data) => data.tireMakers)

      commit('setMakers', makers)

      return makers
    },
    // 車種、フリーワードからタイヤサイズ一覧を取得 Query VehiclePeriods
    async getTireSizesFromVehicleOrFreeWords(_, input: VehiclePeriodsInput) {
      logger.info('車種、フリーワードからタイヤサイズ一覧を取得', { input })
      return await getClient()
        .vehiclePeriods({
          input,
        })
        .then((data) => data.vehiclePeriods)
    },

    async getTireSizes(_, input: TireSizesInput) {
      logger.info('各タイヤサイズ取得', { input })
      return await getClient()
        .tireSizes({ input })
        .then((data) => data.tireSizes)
    },
  }
)

export type RootState = ReturnType<typeof state>
