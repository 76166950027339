
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
      default: false,
    },
    text: {
      type: String,
      required: true,
    },
    btnText: {
      type: String,
      required: false,
      default: 'OK',
    },
    btnColor: {
      type: String,
      required: false,
      default: 'blue darken-2',
    },
    persistent: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    clickedBtn() {
      this.$emit('clicked')
    },
    close() {
      this.$emit('close')
    },
  },
}
