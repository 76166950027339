
import {
  defineComponent,
  ref,
  useMeta,
  onMounted,
  onUnmounted,
} from '@nuxtjs/composition-api'
import useWindowResize from '~/use/useWindowResize'

export default defineComponent({
  setup() {
    // viewportWidthが375px未満のとき倍率縮小する
    const { width } = useWindowResize()
    const setViewport = (option: string) => {
      const meta = document.querySelector('meta[name="viewport"]')
      if (meta) {
        meta.setAttribute('content', option)
      }
    }

    const handleResize = () => {
      const option =
        width.value > 375 ? 'width=device-width, initial-scale=1' : 'width=375'
      setViewport(option)
    }

    onMounted(() => {
      handleResize()
      window.addEventListener('resize', handleResize)
    })

    onUnmounted(() => {
      window.removeEventListener('resize', handleResize)
    })
  },
  head: {},
})
