import { actionTree, getterTree, mutationTree } from 'typed-vuex'
import { VehiclesRegistrationModel } from '~/compositions/useVehicle/type'

const defaultState = () => {
  return {
    _vehicle: null as VehiclesRegistrationModel | null,
  }
}

export const state = () => defaultState()

export const getters = getterTree(state, {
  vehicle: (state) => state._vehicle,
})

export const mutations = mutationTree(state, {
  setVehicle(state, val: VehiclesRegistrationModel | null) {
    state._vehicle = val
  },
  clear(state) {
    Object.assign(state, defaultState())
  },
})

export const actions = actionTree({ state, getters, mutations }, {})

export type RootState = ReturnType<typeof state>
