import { actionTree, getterTree, mutationTree } from 'typed-vuex'
import { getClient } from '~/graphql/client'
import {
  PurchaseDetailsInput,
  QuotationDetailsInput,
} from '~/graphql/generated/types'
import { logger } from '~/utils/logger'

const defaultState = () => {
  return {}
}

export const state = () => defaultState()

export const getters = getterTree(state, {})

export const mutations = mutationTree(state, {
  clear(state) {
    Object.assign(state, defaultState())
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    // 購入情報一覧
    async purchases() {
      logger.info('購入情報一覧')
      return await getClient()
        .purchases({ input: {} })
        .then((data) => data.purchases)
    },
    // 購入情報詳細
    async purchaseDetails(_, input: PurchaseDetailsInput) {
      logger.info('購入情報詳細')
      return await getClient()
        .purchaseDetails({ input })
        .then((data) => data.purchaseDetails.purchaseInfo!)
    },
    // 見積もり履歴一覧	Query	quotations
    async quotations() {
      logger.info('見積もり履歴一覧')
      return await getClient()
        .quotations()
        .then((data) => data.quotations)
    },
    // 見積もり履歴詳細	Query	quotationDetails
    async quotationDetails(_, input: QuotationDetailsInput) {
      logger.info('見積もり履歴詳細', { input })
      return await getClient()
        .quotationDetails({
          input,
        })
        .then((data) => data.quotationDetails)
    },
  }
)

export type RootState = ReturnType<typeof state>
