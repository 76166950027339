
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    to: {
      type: String,
      required: false,
      default: null,
    },
    href: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    colorClass() {
      if (this.disabled) {
        return 'bl_roundBtn-disabled'
      }
      if (this.color === 'grey') {
        return 'bl_roundBtn-grey'
      }
      if (this.color === 'black') {
        return 'bl_roundBtn-black'
      }
      if (this.color === 'yellow') {
        return 'bl_roundBtn-yellow'
      }
      return 'bl_roundBtn-red'
    },
  },
  methods: {
    clicked() {
      if (this.disabled) return
      this.$emit('clicked')
    },
  },
}
