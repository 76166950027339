import jwt from 'jsonwebtoken'
import moment from 'moment-timezone'
import { actionTree, getterTree, mutationTree } from 'typed-vuex'
import { LocalStorageKeys } from '~/constants'
import { getClient } from '~/graphql/client'
import { liff } from '~/utils/liff'
import { logger } from '~/utils/logger'

export interface SessionToken {
  lineId: string
  channelId: string
  issuedAt: string
  expiredAt: string
}

const defaultState = () => {
  return {
    _sessionToken: undefined as SessionToken | undefined,
  }
}

export const state = () => defaultState()

export const getters = getterTree(state, {
  loggedIn: (state) => !!state._sessionToken,
  sessionToken: (state) => state._sessionToken,
  nearExpiration: (state) => {
    if (!state._sessionToken) {
      return false
    }

    const timeAfterOneHour = moment().add(10, 'minutes')
    const isNearExpiration = timeAfterOneHour.isAfter(
      moment(state._sessionToken.expiredAt)
    )
    logger.info(
      '認証有効期限チェック: ',
      state._sessionToken.expiredAt,
      `現在時刻より10分以内に有効期限が切れるか = ${isNearExpiration}`
    )

    // 現在時刻より10分以内に有効期限が切れるかチェック
    // （IDトークンの有効期限は1時間）
    return isNearExpiration
  },
})

export const mutations = mutationTree(state, {
  setSessionToken(
    state,
    params: {
      channelId: string
      decodedToken: any
    }
  ) {
    const { channelId, decodedToken } = params
    logger.info('decodedToken', decodedToken)
    state._sessionToken = {
      lineId: decodedToken.sub,
      channelId,
      issuedAt: moment(new Date(decodedToken.iss * 1000)).toISOString(),
      expiredAt: moment(new Date(decodedToken.exp * 1000)).toISOString(),
    }
  },
  clear(state) {
    Object.assign(state, defaultState())
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    async login({ commit }): Promise<void> {
      logger.info('LINEユーザー認証開始')
      const channelId = liff.getDecodedIDToken()?.aud!

      const { accessToken } = await getClient()
        .authenticate({
          input: {
            lineIdToken: liff.getIDToken(),
          },
        })
        .then((data) => data.authenticate)

      logger.info('ログイン成功', accessToken)

      // リポジトリで仕様するためlocalStorageに直接保存
      localStorage.setItem(LocalStorageKeys.accessToken, accessToken)

      const decodedToken = jwt.decode(accessToken) as any

      commit('setSessionToken', {
        channelId,
        decodedToken,
      })
    },
  }
)

export type RootState = ReturnType<typeof state>
