// タイヤ検索
// APIは数値だが検索用に文字列で定義
export enum TireSearchVehicleType {
  TRUCK = '1',
  OTHERS = '2',
}
export const VehicleTypeSelectItems = [
  { value: TireSearchVehicleType.OTHERS, text: '乗用車' },
  { value: TireSearchVehicleType.TRUCK, text: '商用車' },
]

export enum TireSearchSort {
  // 安い順
  CHEAP = '1',
  // 高い順
  EXPENSIVE = '2',
  // おすすめ順
  RECOMMENDED = '3',
}
export const TireSearchSortSelectItems = [
  { value: TireSearchSort.CHEAP, text: '安い順' },
  { value: TireSearchSort.EXPENSIVE, text: '高い順' },
  // 初回リリースではおすすめ順を表示しない
  // { value: TireSearchSort.RECOMMENDED, text: 'おすすめ順' },
]

export enum TireSearchStock {
  // 在庫なし
  NONE = '1',
  // 在庫あり
  EXISTENCE = '0',
}

export const StockSelectItems = [
  { value: '', text: 'すべて' },
  { value: TireSearchStock.EXISTENCE, text: '在庫あり' },
  { value: TireSearchStock.NONE, text: '在庫なし' },
]

export enum TireSearchTireKind {
  // 夏タイヤ
  SUMMER = '1',
  // オールシーズン
  ALL_SEASON = '2',
  // 冬タイヤ
  WINTER = '3',
}

export const TireKindSelectItems = [
  { value: TireSearchTireKind.SUMMER, text: '夏タイヤ' },
  { value: TireSearchTireKind.ALL_SEASON, text: 'オールシーズン' },
  { value: TireSearchTireKind.WINTER, text: '冬タイヤ' },
]

export enum TireSearchMakerGroup {
  // メジャー
  MAJOR = '0',
  // アジアン
  ASIAN = '1',
  // 両方に属す
  BOTH = '2',
}

export const MakerGroupSelectItems = [
  { value: TireSearchMakerGroup.MAJOR, text: 'メジャー' },
  { value: TireSearchMakerGroup.ASIAN, text: 'アジアン' },
  { value: TireSearchMakerGroup.BOTH, text: '指定なし' },
]

export const TireSearchWidthDefault = '195'

export const TireSearchFlatDefault = '65'

export const TireSearchRimDefault = '15'

export const TireSearchMakerDefault = []

export const TireSearchCarTypeDefault = []

export const TireSearchOffsetDefault = 0

// 1ページで表示する件数
export const TireSearchLimitDefault = 100

export const TireSearchDefaultParams = () => ({
  type: TireSearchVehicleType.OTHERS,
  tireKind: TireSearchTireKind.SUMMER,
  stock: TireSearchStock.EXISTENCE,
  sortMode: TireSearchSort.CHEAP,
  width: TireSearchWidthDefault,
  flat: TireSearchFlatDefault,
  rim: TireSearchRimDefault,
  maker: TireSearchMakerDefault,
  carType: TireSearchCarTypeDefault,
  makerGroup: TireSearchMakerGroup.BOTH,
  offset: TireSearchOffsetDefault,
  limit: TireSearchLimitDefault,
})

export const TireSizesDefaultParams = {
  width: undefined,
  flat: undefined,
  rim: undefined,
}
