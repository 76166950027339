
import {
  computed,
  defineComponent,
  wrapProperty,
} from '@nuxtjs/composition-api'

const useAccessor = wrapProperty('$accessor', false)
export default defineComponent({
  setup() {
    const accessor = useAccessor()

    return {
      isOpen: computed(() => accessor.error.showError),
      close: () => accessor.error.setShowError(false),
    }
  },
})
