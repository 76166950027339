
import { defineComponent, ref } from '@nuxtjs/composition-api'
import usePath from '~/use/usePath'

export default defineComponent({
  setup() {
    const { path } = usePath()

    const menuList = ref([
      {
        icon: 'mdi-card-account-details',
        name: 'お客様情報',
        link: path.mypage,
      },
      {
        icon: 'mdi-camera',
        name: '車検証読取',
        link: path.vehiclesRegistration,
      },
      {
        icon: 'mdi-car-side',
        name: 'タイヤ見積り',
        link: path.quotations,
      },
      {
        icon: 'mdi-home-map-marker',
        name: '店舗検索',
        url: path.beelineShopSearch,
        target: '_blank',
      },
    ])

    return {
      menuList,
    }
  },
})
