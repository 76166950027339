import Vue from 'vue'
import { Context } from '@nuxt/types'
import {
  ApplicationError,
  NotFoundError,
  RequiredLiffInitError,
  UnauthorizedError,
} from '~/errors'
import { logger } from '~/utils/logger'

export default (_context: Context) => {
  const handle = (error: Error) => {
    logger.info('エラーハンドラーでキャッチ')
    if (error instanceof UnauthorizedError) {
      logger.info('認証の有効期限切れ')
      // ログイン画面で再認証
      _context.app.$accessor.auth.clear()
      _context.app.router?.push('/')
    } else if (error instanceof RequiredLiffInitError) {
      logger.info(
        '本番環境以外はLIFF初期化エラーはLIFF側でログインを促すため無視'
      )
    } else if (error instanceof NotFoundError) {
      logger.info(error)
      _context.error({ statusCode: 404, message: error.message })
    } else if (error instanceof ApplicationError) {
      logger.error(error)
      logger.error('アプリケーションエラーが発生しました\n' + error.message)
      _context.app.$accessor.error.setShowError(true)
    } else {
      logger.error(error)
      logger.error('システムエラーが発生しました\n' + error.message)
      _context.app.$accessor.error.setShowError(true)
    }
  }

  Vue.config.errorHandler = (err, _vm, info) => {
    logger.info(`Captured in Vue.config.errorHandler: ${info}`)
    handle(err)
  }
  window.addEventListener('error', (event) => {
    logger.info('Captured in error EventListener')
    handle(event.error)
  })
  window.addEventListener('unhandledrejection', (event) => {
    logger.info('Captured in unhandledrejection EventListener')
    handle(event.reason)
  })
}
