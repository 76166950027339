import { actionTree, getterTree, mutationTree } from 'typed-vuex'
import { getClient } from '~/graphql/client'
import { PutLogInput } from '~/graphql/generated/types'
import { logger } from '~/utils/logger'

const defaultState = () => {
  return {}
}

export const state = () => defaultState()

export const getters = getterTree(state, {})

export const mutations = mutationTree(state, {
  clear(state) {
    Object.assign(state, defaultState())
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    async putLog(_, input: PutLogInput) {
      try {
        return await getClient()
          .putLog({ input })
          .then((data) => data.putLog)
      } catch (err) {
        logger.error({ msg: 'ログ送信でエラーが発生しました。', err })
      }
    },
  }
)

export type RootState = ReturnType<typeof state>
