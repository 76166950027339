import { actionTree, getterTree, mutationTree } from 'typed-vuex'

const defaultState = () => {
  return {
    _showError: false,
    _errorMessage: null as string | null,
  }
}

export const state = () => defaultState()

export const getters = getterTree(state, {
  showError: (state) => state._showError,
  errorMessage: (state) => state._errorMessage,
})

export const mutations = mutationTree(state, {
  setShowError(state, val: boolean) {
    state._showError = val
  },
  setErrorMessage(state, val: string) {
    state._errorMessage = val
  },
  clear(state) {
    Object.assign(state, defaultState())
  },
})

export const actions = actionTree({ state, getters, mutations }, {})

export type RootState = ReturnType<typeof state>
