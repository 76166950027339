import { actionTree, getterTree, mutationTree } from 'typed-vuex'
import { getClient } from '~/graphql/client'
import {
  CreateVehicleInput,
  DeleteVehicleInput,
  VehicleDetailsInput,
  VehicleTypesByMakerInput,
  VehicleTypesInput,
} from '~/graphql/generated/types'
import { logger } from '~/utils/logger'

const defaultState = () => {
  return {}
}

export const state = () => defaultState()

export const getters = getterTree(state, {})

export const mutations = mutationTree(state, {
  clear(state) {
    Object.assign(state, defaultState())
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    // 車両情報一覧	Query	vehicles
    async vehicles() {
      logger.info('車両情報一覧を取得')
      return await getClient()
        .vehicles()
        .then((data) => data.vehicles)
    },
    // 車両メーカ一覧	Query	vehicles
    async vehicleMakers() {
      logger.info('車両メーカー一覧を取得')
      return await getClient()
        .vehicleMakers()
        .then((data) => data.vehicleMakers)
    },
    // 車両情報詳細	Query	vehicleDetails
    async vehicleDetails(_, input: VehicleDetailsInput) {
      logger.info('車両情報詳細', { input })
      return await getClient()
        .vehicleDetails({
          input,
        })
        .then((data) => data.vehicleDetails)
    },
    // 車両情報削除	Mutation	deleteVehicle
    async deleteVehicle(_, input: DeleteVehicleInput) {
      logger.info('車両情報削除', { input })
      return await getClient()
        .deleteVehicle({
          input,
        })
        .then((data) => data.deleteVehicle)
    },
    // 車両情報登録	Mutation	createVehicle
    async createVehicle(_, input: CreateVehicleInput) {
      logger.info('車両情報登録', { input })
      return await getClient()
        .createVehicle({
          input,
        })
        .then((data) => data.createVehicle)
    },
    // 型式車両一覧
    async vehicleTypes(_, input: VehicleTypesInput) {
      logger.info('型式車両一覧を取得')
      return await getClient()
        .vehicleTypes({ input })
        .then((data) => data.vehicleTypes)
    },
    // メーカーから車種一覧取得
    async vehicleTypesByMaker(_, input: VehicleTypesByMakerInput) {
      logger.info('メーカーから車種一覧を取得')
      return await getClient()
        .vehicleTypesByMaker({ input })
        .then((data) => data.vehicleTypesByMaker)
    },
  }
)

export type RootState = ReturnType<typeof state>
