
export default {
  data() {
    return {
      show: false,
      message: '',
      color: '',
      icon: '',
    }
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'snackbar/showSuccessMessage') {
        this.show = true
        this.message = state.snackbar.content
        this.color = 'green lighten-2'
        this.icon = 'mdi-check-circle'
      }
      if (mutation.type === 'snackbar/showErrorMessage') {
        this.show = true
        this.message = state.snackbar.content
        this.color = 'red lighten-2'
        this.icon = 'mdi-alert-circle'
      }
    })
  },
}
