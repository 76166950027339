/* eslint-disable camelcase */
import Vue from 'vue'
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
  extend,
} from 'vee-validate'
import ja from 'vee-validate/dist/locale/ja'
import {
  required,
  max,
  min,
  min_value,
  email,
  length,
  confirmed,
} from 'vee-validate/dist/rules'

configure({
  bails: false,
})

extend('required', { ...required })
extend('max', { ...max })
extend('min', { ...min })
extend('min_value', { ...min_value })
extend('length', { ...length })
extend('email', { ...email })
extend('confirmed', { ...confirmed })
extend('custom_password', {
  message: '半角英字、数字、記号を組み合わせて 8 文字以上で入力してください',
  validate: (value) => {
    const strongRegex = /^(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
    return strongRegex.test(value)
  },
})
extend('kana', {
  validate(value) {
    return typeof value === 'string' && /^([ァ-ン]|ー)+$/.test(value)
  },
  message: '{_field_}はカタカナでなければなりません',
})
extend('hiragana', {
  validate(value) {
    return typeof value === 'string' && /^([ぁ-ん])+$/.test(value)
  },
  message: '{_field_}はひらがなでなければなりません',
})
extend('number', {
  validate(value) {
    return typeof value === 'string' && /^([\d])+$/.test(value)
  },
  message: '{_field_}は数値でなければなりません',
})

localize('ja', ja)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
