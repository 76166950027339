import { actionTree, getterTree, mutationTree } from 'typed-vuex'
import { TireViewModel } from '~/compositions/useTire/type'
import {
  TireSearchDefaultParams,
  TireSearchFlatDefault,
  TireSearchRimDefault,
  TireSearchWidthDefault,
} from '~/constants/tireSeaarch'
import { CreateQuotationInput } from '~/graphql/generated/types'
const storage = JSON.parse(localStorage.getItem('vuex') || '{}')
const defaultState = () => {
  return {
    _tire: null as TireViewModel | null,
    _tires: [] as Omit<CreateQuotationInput, 'shopId'>[],
    _searchResults: [],
    _searchedParams: {
      ...TireSearchDefaultParams(),
      width:
        storage._quotation?._searchedParams.width || TireSearchWidthDefault,
      flat: storage._quotation?._searchedParams.flat || TireSearchFlatDefault,
      rim: storage._quotation?._searchedParams.rim || TireSearchRimDefault,
    },
    _scroll: 0,
    _panel: 0,
    _isCompleteSearched: false,
    _isAllowGetLocation: false,
    _isSavedVuexSearchParams: false, // 検索した時に使う
  }
}

export const state = () => defaultState()

export const getters = getterTree(state, {
  tire: (state) => state._tire,
  tires: (state) => state._tires,
  searchResults: (state) => state._searchResults,
  searchedParams: (state) => state._searchedParams,
  scroll: (state) => state._scroll,
  panel: (state) => state._panel,
  isCompleteSearched: (state) => state._isCompleteSearched,
  isAllowGetLocation: (state) => state._isAllowGetLocation,
  isSavedVuexSearchParams: (state) => state._isSavedVuexSearchParams,
})

export const mutations = mutationTree(state, {
  setTire(state, val: TireViewModel | null) {
    state._tire = val
  },
  addTires(state, val: Omit<CreateQuotationInput, 'shopId'>) {
    state._tires = [...state._tires, val]
  },
  setSearchResults(state, val) {
    state._searchResults = val
  },
  setSearchedParams(state, val) {
    state._searchedParams = val
  },
  setScroll(state, val) {
    state._scroll = val
  },
  changePanel(state, val) {
    state._panel = val
  },
  changeIsCompleteSearched(state, val: boolean) {
    state._isCompleteSearched = val
  },
  changeIsAllowGetLocation(state, val: boolean) {
    state._isAllowGetLocation = val
  },
  changeIsSavedVuexSearchParams(state, val: boolean) {
    state._isSavedVuexSearchParams = val
  },
  clear(state) {
    Object.assign(state, defaultState())
  },
  resetSearchParams(state) {
    Object.assign(state._searchedParams, TireSearchDefaultParams())
  },
})

export const actions = actionTree({ state, getters, mutations }, {})

export type RootState = ReturnType<typeof state>
