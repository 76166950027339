
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    error: {
      type: Object,
      default: null,
      required: false,
    },
    buttonText: {
      type: String,
      default: '',
      required: true,
    },
    heading: {
      type: String,
      default: '',
      required: false,
    },
    text: {
      type: String,
      default: '',
      required: false,
    },
    otherError: {
      type: String,
      default: '',
      required: false,
    },
  },
  setup() {},
})
