export const LocalStorageKeys = {
  accessToken: 'accessToken',
  redirectPath: 'redirectPath',
}

// 税率
export const TAX_RATE = 1.1

export const customerKindList = [
  { text: '個人', value: 0 },
  { text: '法人', value: 1 },
]

export const sexList = [
  { text: '男性', value: 0 },
  { text: '女性', value: 1 },
]

export const prefectureList = [
  '北海道',
  '青森県',
  '岩手県',
  '宮城県',
  '秋田県',
  '山形県',
  '福島県',
  '茨城県',
  '栃木県',
  '群馬県',
  '埼玉県',
  '千葉県',
  '東京都',
  '神奈川県',
  '新潟県',
  '富山県',
  '石川県',
  '福井県',
  '山梨県',
  '長野県',
  '岐阜県',
  '静岡県',
  '愛知県',
  '三重県',
  '滋賀県',
  '京都府',
  '大阪府',
  '兵庫県',
  '奈良県',
  '和歌山県',
  '鳥取県',
  '島根県',
  '岡山県',
  '広島県',
  '山口県',
  '徳島県',
  '香川県',
  '愛媛県',
  '高知県',
  '福岡県',
  '佐賀県',
  '長崎県',
  '熊本県',
  '大分県',
  '宮崎県',
  '鹿児島県',
  '沖縄県',
]

// タイヤメーカーIDと画像のマッピング
// タイヤ画像素材順
export const tireImages = [
  {
    id: 1,
    name: 'yokohama.png',
  },
  {
    id: 11,
    name: 'toyo.png',
  },
  {
    id: 8,
    name: 'pirelli.png',
  },
  {
    id: 23,
    name: 'nexen.png',
  },
  {
    id: 25,
    name: 'nankang.png',
  },
  {
    id: 7,
    name: 'michelin.png',
  },
  {
    id: 6,
    name: 'hankook.png',
  },
  {
    id: 5,
    name: 'goodyear.png',
  },
  {
    id: 18,
    name: 'falken.png',
  },
  {
    id: 4,
    name: 'dunlop.png',
  },
  {
    id: 2,
    name: 'bridgestone.png',
  },
  {
    id: 16,
    name: 'bfgoodrich.png',
  },
]
