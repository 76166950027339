import { actionTree, getterTree, mutationTree } from 'typed-vuex'
import { getClient } from '~/graphql/client'
import {
  MarkListsInput,
  MarkListResultAttributesFragment,
} from '~/graphql/generated/types'
import { logger } from '~/utils/logger'

const defaultState = () => {
  return {
    _markList: [] as MarkListResultAttributesFragment[],
  }
}

export const state = () => defaultState()

export const getters = getterTree(state, {
  markList: (state) => state._markList,
})

export const mutations = mutationTree(state, {
  setMarkList(state, val: MarkListResultAttributesFragment[]) {
    state._markList = val
  },
  clear(state) {
    Object.assign(state, defaultState())
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    async getMarkList(
      { getters, commit },
      input: MarkListsInput
    ): Promise<MarkListResultAttributesFragment[]> {
      if (getters.markList.length !== 0) {
        logger.debug('アンケート マーク一覧のキャッシュを使用')
        return getters.markList
      }

      logger.info('アンケート マーク一覧を取得', { input })
      const markList = await getClient()
        .markLists({ input })
        .then((data) => data.markLists)

      commit('setMarkList', markList)

      return markList
    },
  }
)

export type RootState = ReturnType<typeof state>
