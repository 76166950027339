// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text-black_tSyGg{color:rgba(0,0,0,.871)}.text-white_CdQjQ{color:#fff}.text-red_4xCri{color:#c43}.text-blue_gtZrL{color:#4587c1}.color-red_5jOqI{background:#c43}.color-blue-grey_VTLDC{background:#54617a}.color-white_vU6TG{background:#fff}.pa-1-5_gwqAH{padding:6px}.mt-20_N2395{margin-top:80px}.mt-24_ZyPC8{margin-top:96px}p{margin:0;padding:0}a{-webkit-text-decoration:none;text-decoration:none}.v-text-field--outlined_0gv\\+u fieldset,.v-text-field--outlined_0gv\\+u.v-input--is-focused_-9Xo1 fieldset{border-width:0}.v-text-field--outlined_0gv\\+u.v-input--has-state_U1IBk fieldset{border:2px solid red!important}.v-input__slot_fnhJ7{min-height:auto!important;padding:0 12px!important}.v-input_WWyDh{caret-color:#1867c0!important;font-size:14px!important}.v-input__append-inner_nOTjG{margin-top:4px!important}.v-slide-group__next_He9DV,.v-slide-group__prev_GX-mT{flex:0 1!important;min-width:0!important}.v-expansion-panel-header_y6KlB{min-height:40px!important}.v-btn_mn90H{min-width:auto!important}.v-tab_AcVeZ{padding:0 12px!important}.v-item-group_cV7q0.v-bottom-navigation_yyThr .v-btn_mn90H.v-size--default_npJuc{height:inherit}.theme--light_zNk43.v-bottom-navigation_yyThr{background-color:#eee!important}.v-bottom-navigation_yyThr{height:auto!important}.v-bottom-navigation_yyThr .v-btn_mn90H{margin-bottom:env(safe-area-inset-bottom)}h1{font-size:20px}.bl_error_sEyDZ{text-align:center}.bl_error_btn_6lXV8{margin:96px 24px 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text-black": "text-black_tSyGg",
	"text-white": "text-white_CdQjQ",
	"text-red": "text-red_4xCri",
	"text-blue": "text-blue_gtZrL",
	"color-red": "color-red_5jOqI",
	"color-blue-grey": "color-blue-grey_VTLDC",
	"color-white": "color-white_vU6TG",
	"pa-1-5": "pa-1-5_gwqAH",
	"mt-20": "mt-20_N2395",
	"mt-24": "mt-24_ZyPC8",
	"v-text-field--outlined": "v-text-field--outlined_0gv+u",
	"v-input--is-focused": "v-input--is-focused_-9Xo1",
	"v-input--has-state": "v-input--has-state_U1IBk",
	"v-input__slot": "v-input__slot_fnhJ7",
	"v-input": "v-input_WWyDh",
	"v-input__append-inner": "v-input__append-inner_nOTjG",
	"v-slide-group__next": "v-slide-group__next_He9DV",
	"v-slide-group__prev": "v-slide-group__prev_GX-mT",
	"v-expansion-panel-header": "v-expansion-panel-header_y6KlB",
	"v-btn": "v-btn_mn90H",
	"v-tab": "v-tab_AcVeZ",
	"v-item-group": "v-item-group_cV7q0",
	"v-bottom-navigation": "v-bottom-navigation_yyThr",
	"v-size--default": "v-size--default_npJuc",
	"theme--light": "theme--light_zNk43",
	"bl_error": "bl_error_sEyDZ",
	"bl_error_btn": "bl_error_btn_6lXV8"
};
module.exports = ___CSS_LOADER_EXPORT___;
