import { GraphQLClient } from 'graphql-request'
import { LocalStorageKeys } from '~/constants'
import {
  BadRequestError,
  ConflictError,
  ForbiddenError,
  NotFoundError,
  SystemError,
  UnauthorizedError,
} from '~/errors'
import { getSdk, SdkFunctionWrapper } from '~/graphql/generated/types'
import { logger } from '~/utils/logger'

const client = new GraphQLClient(`${process.env.LIFF_FE_BACKEND_URL!}/graphql`)

export const getClient = (
  options: { asGuest: boolean } = { asGuest: false }
) => {
  const headers: any = {}
  if (!options.asGuest) {
    const accessToken = localStorage.getItem(LocalStorageKeys.accessToken)
    headers.Authorization = `Bearer ${accessToken}`
  }

  const requestWrapper: SdkFunctionWrapper = async <T>(
    action: (requestHeaders?: Record<string, string>) => Promise<T>
  ): Promise<T> => {
    try {
      const response = await action(headers)

      logger.info('GraphQL APIレスポンス', response)

      return response
    } catch (error: any) {
      logger.info('GraphQL API実行でエラー発生', error)
      const exception = error?.response?.errors[0]?.extensions?.exception

      if (exception?.type === 'BadRequestError') {
        logger.info(error)
        throw new BadRequestError(exception.title)
      }

      if (exception?.type === 'UnauthorizedError') {
        logger.info(error)
        throw new UnauthorizedError(exception.title)
      }

      if (exception?.type === 'ForbiddenError') {
        logger.info(error)
        throw new ForbiddenError(exception.title)
      }

      if (exception?.type === 'NotFoundError') {
        logger.info(error)
        throw new NotFoundError(exception.title)
      }

      if (exception?.type === 'ConflictError') {
        logger.info(error)
        throw new ConflictError(exception.title)
      }

      if (exception?.type === 'SystemError') {
        logger.error('GraphQLリクエストでシステムエラー発生', error)
        throw new SystemError(exception.title)
      }

      logger.error('GraphQLリクエストで予期しないエラー発生', error)
      throw error
    }
  }

  return getSdk(client, requestWrapper)
}
