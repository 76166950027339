import Vue from 'vue'
import Bugsnag, { Client } from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

// eslint-disable-next-line import/no-mutable-exports
export let bugsnagClient: Client

export default function ({ $config }: any, inject: any) {
  bugsnagClient = Bugsnag.start({
    apiKey: $config.bugsnag.apiKey,
    appVersion: `${$config.bugsnag.appVersion}`,
    plugins: [new BugsnagPluginVue()],
    releaseStage: process.env.STAGE,
  })
  const bugsnagPluginVueResult = Bugsnag.getPlugin('vue')
  if (bugsnagPluginVueResult) bugsnagPluginVueResult.installVueErrorHandler(Vue)

  inject('bugsnag', bugsnagClient)
}
