import { logger } from './logger'
import { RequiredLiffInitError } from '~/errors'

export const liff = window.liff

export const liffInit = async (liffId: string) => {
  if (liff.id && liff.getDecodedIDToken()) {
    logger.info('LIFF初期化済み')
  } else {
    logger.info(`LIFFを初期化: liffId = ${liffId}`)
    try {
      await liff.init({ liffId })
    } catch (error) {
      logger.error('LIFFの初期化に失敗')
      throw error
    }

    // 本番環境以外はブラウザからのLIFFログインを有効化
    if (process.env.STAGE !== 'prd') {
      if (!liff.isLoggedIn()) {
        await liff.login({ redirectUri: location.href })
        throw new RequiredLiffInitError('LIFF初期化エラー')
      }
    }
  }

  // LIFFはIDトークンをキャッシュするため
  // 有効期限が切れていたらログアウトする
  if (
    !liff.getDecodedIDToken() ||
    liff.getDecodedIDToken().exp < new Date().getTime() / 1000 + 60
  ) {
    logger.info('LIFFの有効期限が近いためliffを一度ログアウト')
    liff.logout()
    await liff.login({ redirectUri: location.href })
    throw new RequiredLiffInitError('LIFF初期化エラー')
  }
  logger.info(`LIFFの初期化完了: liffId = ${liffId}`)
}

export const getIDToken = () => {
  return liff.getIDToken()
}

export const parseQuery = (_query: any) => {
  const defaultReturnParams = {
    path: undefined,
    query: {} as any,
  }
  const liffState = _query['liff.state']
  if (!liffState) {
    return defaultReturnParams
  }

  const query: Record<string, string> = {}
  const matched = decodeURIComponent(liffState).match(
    /(?<path>[^?]*)\?(?<queryString>.*)/
  )

  if (!matched) {
    return defaultReturnParams
  }

  const pairs = matched.groups!.queryString.split('&')

  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    query[pair[0]] = pair[1]
  }

  return {
    path: matched.groups!.path,
    query,
  }
}
