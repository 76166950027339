// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text-black_bLItj{color:rgba(0,0,0,.871)}.text-white_Zu9Qg{color:#fff}.text-red_5lSdS{color:#c43}.text-blue_siA9p{color:#4587c1}.color-red_hMxU3{background:#c43}.color-blue-grey_O6zxe{background:#54617a}.color-white_CkY7T{background:#fff}.pa-1-5_2ucsJ{padding:6px}.mt-20_TjUaJ{margin-top:80px}.mt-24_yr7Ce{margin-top:96px}p{margin:0;padding:0}a{-webkit-text-decoration:none;text-decoration:none}.v-text-field--outlined_1cru\\+ fieldset,.v-text-field--outlined_1cru\\+.v-input--is-focused_qEtXz fieldset{border-width:0}.v-text-field--outlined_1cru\\+.v-input--has-state_S-5\\+4 fieldset{border:2px solid red!important}.v-input__slot_Ru-OG{min-height:auto!important;padding:0 12px!important}.v-input_5RX1Q{caret-color:#1867c0!important;font-size:14px!important}.v-input__append-inner_gIlMS{margin-top:4px!important}.v-slide-group__next_k8EyW,.v-slide-group__prev_zcput{flex:0 1!important;min-width:0!important}.v-expansion-panel-header_UYedy{min-height:40px!important}.v-btn_HBgIj{min-width:auto!important}.v-tab_IgVNS{padding:0 12px!important}.v-item-group_iRSFl.v-bottom-navigation_3cPgP .v-btn_HBgIj.v-size--default_153iw{height:inherit}.theme--light_bDFQO.v-bottom-navigation_3cPgP{background-color:#eee!important}.v-bottom-navigation_3cPgP{height:auto!important}.v-bottom-navigation_3cPgP .v-btn_HBgIj{margin-bottom:env(safe-area-inset-bottom)}.bl_error_title_sGSkU{font-size:26px;font-weight:700;line-height:1.3;margin-top:24px;text-align:center;white-space:pre-wrap}.bl_error_border_WAdmk{border-bottom:2px solid #c43;margin:10px auto 0;width:40px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text-black": "text-black_bLItj",
	"text-white": "text-white_Zu9Qg",
	"text-red": "text-red_5lSdS",
	"text-blue": "text-blue_siA9p",
	"color-red": "color-red_hMxU3",
	"color-blue-grey": "color-blue-grey_O6zxe",
	"color-white": "color-white_CkY7T",
	"pa-1-5": "pa-1-5_2ucsJ",
	"mt-20": "mt-20_TjUaJ",
	"mt-24": "mt-24_yr7Ce",
	"v-text-field--outlined": "v-text-field--outlined_1cru+",
	"v-input--is-focused": "v-input--is-focused_qEtXz",
	"v-input--has-state": "v-input--has-state_S-5+4",
	"v-input__slot": "v-input__slot_Ru-OG",
	"v-input": "v-input_5RX1Q",
	"v-input__append-inner": "v-input__append-inner_gIlMS",
	"v-slide-group__next": "v-slide-group__next_k8EyW",
	"v-slide-group__prev": "v-slide-group__prev_zcput",
	"v-expansion-panel-header": "v-expansion-panel-header_UYedy",
	"v-btn": "v-btn_HBgIj",
	"v-tab": "v-tab_IgVNS",
	"v-item-group": "v-item-group_iRSFl",
	"v-bottom-navigation": "v-bottom-navigation_3cPgP",
	"v-size--default": "v-size--default_153iw",
	"theme--light": "theme--light_bDFQO",
	"bl_error_title": "bl_error_title_sGSkU",
	"bl_error_border": "bl_error_border_WAdmk"
};
module.exports = ___CSS_LOADER_EXPORT___;
