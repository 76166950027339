export const dummyTires = {
  data: {
    resultStatus: 'success',
    apiKey: 'EiHbUBIhN4jiMtMxElpe8j82Qb3TTVdTve9jtMISwAlrIYNPtpExbKKcWz51oNR8',
    errorFields: [],
    num: 30,
    tireInfo: [
      {
        jan: '2072510302002',
        makerItemCode: null,
        beelineCode: null,
        comCode: '1437',
        makerId: 52,
        makerName: 'ANNAITE',
        itemName: 'AN600',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'H',
        tireXl: 0,
        tireRunFlat: 0,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: null,
        wetSpec: null,
        miniCarFlg: 0,
        minivanFlg: 0,
        suvFlg: 0,
        truckFlg: 0,
        importItem: 0,
        description:
          '【ベーシック】\r\n○推奨車種『セダン、コンパクト、軽自動車』\r\n',
        brandAlphaName: null,
        brandKanaName: null,
        patternAlphaName: 'AN600',
        patternKanaName: null,
        sale1: 4750,
        sale2: 4200,
        sale3: 3950,
        sale4: 3950,
        sale5: 5000,
        sale6: 4450,
        sale7: 3750,
        sale8: 0,
        tireImage: '/imgGetter/productImages/tire/tire_manual/AN600.jpg',
        shopStock: 0,
        warehouseStock: 0,
      },
      {
        jan: null,
        makerItemCode: null,
        beelineCode: '0111951652153',
        comCode: null,
        makerId: 2,
        makerName: 'BRIDGESTONE',
        itemName: 'EP150 (逆輸入)',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'S',
        tireXl: 0,
        tireRunFlat: 0,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: null,
        wetSpec: null,
        miniCarFlg: 0,
        minivanFlg: 0,
        suvFlg: 0,
        truckFlg: 0,
        importItem: 2,
        description:
          '【ハイスペック低燃費】\r\n〇推奨車種『セダン、ミニバン、軽自動車、コンパクト』\r\n低燃費性能（エコ）に優れた環境フラッグシップブランド\r\n低燃費性能と、ウェット性能（雨に強い）を両立。\r\nECOPIAの新車装着タイヤ',
        brandAlphaName: null,
        brandKanaName: null,
        patternAlphaName: 'EP150',
        patternKanaName: null,
        sale1: 8250,
        sale2: 7700,
        sale3: 7450,
        sale4: 7450,
        sale5: 8500,
        sale6: 7950,
        sale7: 7340,
        sale8: null,
        tireImage: '/imgGetter/productImages/tire/tire_manual/EP150.jpg',
        shopStock: 0,
        warehouseStock: 0,
      },
      {
        jan: null,
        makerItemCode: null,
        beelineCode: '2111955656155',
        comCode: '0967',
        makerId: 2,
        makerName: 'BRIDGESTONE',
        itemName: 'EP150 (PRIUS)',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'H',
        tireXl: null,
        tireRunFlat: null,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: null,
        wetSpec: null,
        miniCarFlg: 0,
        minivanFlg: 0,
        suvFlg: 0,
        truckFlg: 0,
        importItem: null,
        description: null,
        brandAlphaName: null,
        brandKanaName: null,
        patternAlphaName: null,
        patternKanaName: null,
        sale1: 8250,
        sale2: 7700,
        sale3: 7450,
        sale4: 7450,
        sale5: 8500,
        sale6: 7950,
        sale7: 7340,
        sale8: 0,
        tireImage: null,
        shopStock: 0,
        warehouseStock: 1,
      },
      {
        jan: '4961914452704',
        makerItemCode: 'PSR00497',
        beelineCode: null,
        comCode: '1024',
        makerId: 2,
        makerName: 'BRIDGESTONE',
        itemName: 'GRVII',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'H',
        tireXl: null,
        tireRunFlat: null,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: 'A',
        wetSpec: null,
        miniCarFlg: 0,
        minivanFlg: 1,
        suvFlg: 1,
        truckFlg: 0,
        importItem: 0,
        description: null,
        brandAlphaName: 'REGNO GRVII',
        brandKanaName: 'レグノ　ジーアールブイツー',
        patternAlphaName: 'GRV2',
        patternKanaName: 'ジーアールブイニ',
        sale1: 12250,
        sale2: 11700,
        sale3: 11450,
        sale4: 11450,
        sale5: 12500,
        sale6: 11950,
        sale7: 11450,
        sale8: 0,
        tireImage: '/imgGetter/productImages/tire/tire_manual/GRV2.jpg',
        shopStock: 0,
        warehouseStock: 0,
      },
      {
        jan: '4961914454715',
        makerItemCode: 'PSR14308',
        beelineCode: null,
        comCode: null,
        makerId: 2,
        makerName: 'BRIDGESTONE',
        itemName: 'PX-RV',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'H',
        tireXl: null,
        tireRunFlat: null,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: 'A',
        wetSpec: 'b',
        miniCarFlg: 0,
        minivanFlg: 1,
        suvFlg: 0,
        truckFlg: 0,
        importItem: 0,
        description:
          '【ミニバン】\r\n〇推奨車種『ミニバン、SUV（一部サイズ）』\r\nふらつきと偏摩耗を抑制するミニバン専用設計\r\nミニバンにも“疲れにくい”快適な操縦安定性（ハンドリング）を実現。\r\n「パワートレッドゴム」と専用設計により耐摩耗性（長持ち）を向上。\r\n■前型『PRV』　■ラベリング『A/b』低燃費タイヤ（エコタイヤ）',
        brandAlphaName: 'Playz PX-RV',
        brandKanaName: 'プレイズ　ピーエックスアールブイ',
        patternAlphaName: 'PX-RV',
        patternKanaName: 'ピーエックスアールブイ',
        sale1: 13440,
        sale2: 12940,
        sale3: 13440,
        sale4: 12940,
        sale5: 13690,
        sale6: 13190,
        sale7: 12940,
        sale8: null,
        tireImage: '/imgGetter/productImages/tire/tire_manual/PX-RV.jpg',
        shopStock: 0,
        warehouseStock: 1,
      },
      {
        jan: '4961914455033',
        makerItemCode: 'PSR14259',
        beelineCode: null,
        comCode: '1235',
        makerId: 2,
        makerName: 'BRIDGESTONE',
        itemName: 'PX',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'H',
        tireXl: null,
        tireRunFlat: null,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: 'AA',
        wetSpec: 'a',
        miniCarFlg: 0,
        minivanFlg: 0,
        suvFlg: 0,
        truckFlg: 0,
        importItem: 0,
        description: null,
        brandAlphaName: 'Playz PX',
        brandKanaName: 'プレイズ　ピーエックス',
        patternAlphaName: 'PX',
        patternKanaName: 'ピーエックス',
        sale1: 13440,
        sale2: 12940,
        sale3: 13440,
        sale4: 12940,
        sale5: 13690,
        sale6: 13190,
        sale7: 12940,
        sale8: 0,
        tireImage: '/imgGetter/productImages/tire/tire_manual/PX.jpg',
        shopStock: 0,
        warehouseStock: 1,
      },
      {
        jan: '4961914457631',
        makerItemCode: 'PSR00315',
        beelineCode: null,
        comCode: '1015',
        makerId: 2,
        makerName: 'BRIDGESTONE',
        itemName: 'NH100RV',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'H',
        tireXl: null,
        tireRunFlat: null,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: 'AA',
        wetSpec: 'b',
        miniCarFlg: 0,
        minivanFlg: 1,
        suvFlg: 0,
        truckFlg: 0,
        importItem: 0,
        description:
          '【ミニバン】\r\n〇推奨車種『ミニバン、SUV（一部サイズ）』\r\n安全性能、長持ち！ミニバン専用\r\nウェット性能（雨に強い）、耐摩耗性能（長持ち）と低燃費性能（エコ）を高次元で実現\r\nミニバン専用設計で、みんなで乗ってもふらつきにくい。\r\n「サイドプロテクト」によりタイヤ側面の損傷を抑制。\r\n■前型『EX20RV』　■ラベリング『AA/b』低燃費タイヤ（エコタイヤ）',
        brandAlphaName: 'ECOPIA NH100 RV',
        brandKanaName: 'エコピア　エヌエッチイチゼロゼロアールブイ',
        patternAlphaName: 'NH100RV',
        patternKanaName: 'エヌエッチイチゼロゼロアールブイ',
        sale1: 9750,
        sale2: 9200,
        sale3: 8950,
        sale4: 8950,
        sale5: 10000,
        sale6: 9450,
        sale7: 8750,
        sale8: null,
        tireImage: '/imgGetter/productImages/tire/tire_manual/NH100RV.jpg',
        shopStock: 0,
        warehouseStock: 0,
      },
      {
        jan: '4961914963323',
        makerItemCode: 'PSR07715',
        beelineCode: null,
        comCode: '1178',
        makerId: 2,
        makerName: 'BRIDGESTONE',
        itemName: 'GR-XII',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'H',
        tireXl: null,
        tireRunFlat: null,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: 'AA',
        wetSpec: 'b',
        miniCarFlg: 0,
        minivanFlg: 0,
        suvFlg: 0,
        truckFlg: 0,
        importItem: 0,
        description:
          '【プレミアムコンフォート】\r\n〇推奨車種『セダン、コンパクト』\r\n進化を遂げた、グレートバランス\r\n新品時・摩耗時ともに上質な静粛性（静かさ）を実現。\r\n優雅な乗り心地と操縦安定性（ハンドリング）を両立。\r\n低燃費性能（エコ）・耐摩耗性能（長持ち）・ウェット性能（雨に強い）を高次元でバランス。\r\n■前型『GR-XI』　■ラベリング『AA/ｂ～A/b』低燃費タイヤ（エコタイヤ）',
        brandAlphaName: 'REGNO GR-XII',
        brandKanaName: 'レグノ　ジーアール・クロスツー',
        patternAlphaName: 'GR-XII',
        patternKanaName: 'ジーアールエックスアイアイ',
        sale1: 13250,
        sale2: 12700,
        sale3: 12450,
        sale4: 12450,
        sale5: 13500,
        sale6: 12950,
        sale7: 12450,
        sale8: null,
        tireImage: '/imgGetter/productImages/tire/tire_manual/GR-X2.jpg',
        shopStock: 0,
        warehouseStock: 0,
      },
      {
        jan: '4961914965730',
        makerItemCode: 'PSR08099',
        beelineCode: null,
        comCode: '1202',
        makerId: 2,
        makerName: 'BRIDGESTONE',
        itemName: 'PX-RVII',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'H',
        tireXl: null,
        tireRunFlat: null,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: 'A',
        wetSpec: 'a',
        miniCarFlg: 0,
        minivanFlg: 1,
        suvFlg: 1,
        truckFlg: 0,
        importItem: 0,
        description: null,
        brandAlphaName: 'Playz PX-RVII',
        brandKanaName: 'プレイズ　ピーエックスアールブイツー',
        patternAlphaName: 'PX-RV2',
        patternKanaName: 'ピーエックスアールブイアイアイ',
        sale1: 10000,
        sale2: 9450,
        sale3: 9200,
        sale4: 9200,
        sale5: 10250,
        sale6: 9700,
        sale7: 8750,
        sale8: null,
        tireImage: '/imgGetter/productImages/tire/tire_manual/PX-RV2.jpg',
        shopStock: 0,
        warehouseStock: 0,
      },
      {
        jan: '8697322148903',
        makerItemCode: null,
        beelineCode: null,
        comCode: null,
        makerId: 2,
        makerName: 'BRIDGESTONE',
        itemName: 'EP150 (9Z逆輸入)',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'H',
        tireXl: 0,
        tireRunFlat: 0,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: null,
        wetSpec: null,
        miniCarFlg: 0,
        minivanFlg: 0,
        suvFlg: 0,
        truckFlg: 0,
        importItem: 2,
        description: null,
        brandAlphaName: null,
        brandKanaName: null,
        patternAlphaName: null,
        patternKanaName: null,
        sale1: 8250,
        sale2: 7700,
        sale3: 7450,
        sale4: 7450,
        sale5: 8500,
        sale6: 7950,
        sale7: 7340,
        sale8: 0,
        tireImage: null,
        shopStock: 0,
        warehouseStock: 1,
      },
      {
        jan: '8697322148200',
        makerItemCode: null,
        beelineCode: null,
        comCode: '0142',
        makerId: 2,
        makerName: 'BRIDGESTONE',
        itemName: 'EP150TZ (逆輸入)',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'H',
        tireXl: 0,
        tireRunFlat: 0,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: null,
        wetSpec: null,
        miniCarFlg: 0,
        minivanFlg: 0,
        suvFlg: 0,
        truckFlg: 0,
        importItem: 2,
        description:
          '【ハイスペック低燃費】\r\n〇推奨車種『セダン、ミニバン、軽自動車、コンパクト』\r\n低燃費性能（エコ）に優れた環境フラッグシップブランド\r\n低燃費性能と、ウェット性能（雨に強い）を両立。\r\nECOPIAの新車装着タイヤ',
        brandAlphaName: null,
        brandKanaName: null,
        patternAlphaName: 'EP150',
        patternKanaName: null,
        sale1: 8000,
        sale2: 7450,
        sale3: 7200,
        sale4: 7200,
        sale5: 8250,
        sale6: 7700,
        sale7: 7030,
        sale8: 0,
        tireImage: '/imgGetter/productImages/tire/tire_manual/EP150.jpg',
        shopStock: 0,
        warehouseStock: 1,
      },
      {
        jan: null,
        makerItemCode: null,
        beelineCode: '0608619565158',
        comCode: '0982',
        makerId: 4,
        makerName: 'DUNLOP',
        itemName: 'SP TOURING R1 (逆輸入)',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'T',
        tireXl: null,
        tireRunFlat: 0,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: null,
        wetSpec: null,
        miniCarFlg: 0,
        minivanFlg: 0,
        suvFlg: 0,
        truckFlg: 0,
        importItem: 2,
        description:
          '【コンフォート】\r\n○推奨車種『セダン、ミニバン、コンパクト』\r\n非対称トレッドパターンを備え、耐偏摩耗性能・耐摩耗性（長持ち）に配慮。\r\n優れたウェット性能（雨に強い）、乗り心地を実現。',
        brandAlphaName: null,
        brandKanaName: null,
        patternAlphaName: 'SP TOURING R1',
        patternKanaName: null,
        sale1: 7250,
        sale2: 6700,
        sale3: 6450,
        sale4: 6450,
        sale5: 7500,
        sale6: 6950,
        sale7: 6450,
        sale8: 0,
        tireImage:
          '/imgGetter/productImages/tire/tire_manual/SP TOURING R1.jpg',
        shopStock: 0,
        warehouseStock: 1,
      },
      {
        jan: '4981160780628',
        makerItemCode: '327764',
        beelineCode: null,
        comCode: null,
        makerId: 4,
        makerName: 'DUNLOP',
        itemName: 'LM5',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'H',
        tireXl: null,
        tireRunFlat: null,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: 'AA',
        wetSpec: 'b',
        miniCarFlg: 0,
        minivanFlg: 0,
        suvFlg: 0,
        truckFlg: 0,
        importItem: 0,
        description:
          '【コンフォート】\r\n○推奨車種『セダン、ミニバン、コンパクト、軽自動車』\r\n優れた静粛性と乗り心地を実感できる快適性能を備えたコンフォートタイヤ\r\n良質な乗り心地と、優れた静粛性（静か）。\r\n安心感のあるウェット性能（雨に強い）。\r\nロングライフを実現する耐摩耗・耐偏摩耗性能（長持ち）。\r\n特殊吸音スポンジ（SILENT CORE）搭載。\r\n■前型『LM704』　■ラべリング『AA/ｂ～AA/ｃ』低燃費タイヤ（エコタイヤ）',
        brandAlphaName: 'LE MANS V',
        brandKanaName: 'ル・マン　ファイブ',
        patternAlphaName: 'LM5',
        patternKanaName: 'エルエムゴ',
        sale1: 10600,
        sale2: 10100,
        sale3: 10600,
        sale4: 10100,
        sale5: 10850,
        sale6: 10350,
        sale7: 10100,
        sale8: null,
        tireImage: '/imgGetter/productImages/tire/tire_manual/LM5.jpg',
        shopStock: 0,
        warehouseStock: 1,
      },
      {
        jan: '4981160951097',
        makerItemCode: '317211',
        beelineCode: null,
        comCode: '0157',
        makerId: 4,
        makerName: 'DUNLOP',
        itemName: 'RV504',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'H',
        tireXl: null,
        tireRunFlat: null,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: null,
        wetSpec: null,
        miniCarFlg: 0,
        minivanFlg: 1,
        suvFlg: 0,
        truckFlg: 0,
        importItem: 0,
        description:
          '【ミニバン】\r\n○推奨車種『ミニバン、コンパクト、軽自動車』\r\nふらつきを抑え、低燃費とロングライフの両立を実現したミニバン専用タイヤ\r\nミニバン特有のふらつきを抑えた高次元の操縦安定性（ハンドリング）。\r\nロングライフで経済的に。優れた耐摩耗・耐偏摩耗性能（長持ち）。\r\n低燃費グレード「AA」の優れた低燃費性能（エコ）。\r\n■前型『RV503☆』　■旧モデル　■後継『RV505』　■ラべリング『AA/ｂ～AA/ｃ』低燃費タイヤ（エコタイヤ）',
        brandAlphaName: 'RV504',
        brandKanaName: 'アールブイゴゼロヨン',
        patternAlphaName: 'RV504',
        patternKanaName: 'アールブイゴゼロヨン',
        sale1: 9270,
        sale2: 8770,
        sale3: 9270,
        sale4: 8770,
        sale5: 9520,
        sale6: 9020,
        sale7: 8770,
        sale8: null,
        tireImage: '/imgGetter/productImages/tire/tire_manual/RV504.jpg',
        shopStock: 0,
        warehouseStock: 1,
      },
      {
        jan: '4992496095522',
        makerItemCode: null,
        beelineCode: null,
        comCode: '0098',
        makerId: 5,
        makerName: 'GOODYEAR',
        itemName: 'GT-ECO STAGE',
        size: '195/65R15',
        tireLi: null,
        tireSr: null,
        tireXl: null,
        tireRunFlat: null,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: null,
        wetSpec: null,
        miniCarFlg: 0,
        minivanFlg: 0,
        suvFlg: 0,
        truckFlg: 0,
        importItem: null,
        description:
          '【ベーシック低燃費】\r\n〇推奨車種『セダン、コンパクト、ミニバン、軽自動車』\r\n低燃費性能と長持ち性能を進化させた、エコ・トク タイヤ\r\nトレッドの発熱を抑え転がり抵抗の低減、低燃費性能（エコ）を実現。\r\nゴム分子の結合力が強く減りを遅くし、耐摩耗性（長持ち）を実現。\r\n■前型『GT-HB』　■旧モデル　■後継『EG01』　■ラべリング『A/ｃ』低燃費タイヤ（エコタイヤ）',
        brandAlphaName: null,
        brandKanaName: null,
        patternAlphaName: 'GT-ES',
        patternKanaName: null,
        sale1: 6750,
        sale2: 6200,
        sale3: 5950,
        sale4: 5950,
        sale5: 7000,
        sale6: 6450,
        sale7: 5950,
        sale8: 0,
        tireImage: '/imgGetter/productImages/tire/tire_manual/GT-ES.jpg',
        shopStock: 0,
        warehouseStock: 1,
      },
      {
        jan: '4992496140062',
        makerItemCode: '05602716',
        beelineCode: null,
        comCode: '1386',
        makerId: 5,
        makerName: 'GOODYEAR',
        itemName: 'EfficientGrip ECO EG01',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'H',
        tireXl: null,
        tireRunFlat: null,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: 'AA',
        wetSpec: 'c',
        miniCarFlg: 0,
        minivanFlg: 0,
        suvFlg: 0,
        truckFlg: 0,
        importItem: 0,
        description: null,
        brandAlphaName: 'EfficientGrip ECO EG01',
        brandKanaName: 'エフィシェントグリップエコイージーゼロワン',
        patternAlphaName: 'EFGEG01',
        patternKanaName: 'イーエフジーイージーゼロワン',
        sale1: 6750,
        sale2: 6200,
        sale3: 5950,
        sale4: 5950,
        sale5: 7000,
        sale6: 6450,
        sale7: 5950,
        sale8: 0,
        tireImage: '/imgGetter/productImages/tire/tire_manual/E-GRIP EG01.jpg',
        shopStock: 0,
        warehouseStock: 0,
      },
      {
        jan: '8808563388694',
        makerItemCode: null,
        beelineCode: null,
        comCode: null,
        makerId: 24,
        makerName: 'LAUFENN',
        itemName: 'LK41',
        size: '195/65R15',
        tireLi: null,
        tireSr: null,
        tireXl: 1,
        tireRunFlat: null,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: null,
        wetSpec: null,
        miniCarFlg: 0,
        minivanFlg: 0,
        suvFlg: 0,
        truckFlg: 0,
        importItem: null,
        description:
          '【ベーシック】\r\n○推奨車種『セダン、ミニバン、コンパクト、軽自動車、SUV（一部サイズ）』\r\n優れたトータルバランスを追求したマルチパフォーマンスタイヤ\r\n高いウェットグリップ性能（雨に強い）だけでなく、ドライグリップにも配慮。\r\n操縦安定性能（ハンドリング）や快適な乗り心地を実現。',
        brandAlphaName: null,
        brandKanaName: null,
        patternAlphaName: 'LK41',
        patternKanaName: null,
        sale1: 5500,
        sale2: 4950,
        sale3: 4700,
        sale4: 4700,
        sale5: 5750,
        sale6: 5200,
        sale7: 4700,
        sale8: 0,
        tireImage: '/imgGetter/productImages/tire/tire_manual/LK41.jpg',
        shopStock: 0,
        warehouseStock: 1,
      },
      {
        jan: '8808563379371',
        makerItemCode: '1017701',
        beelineCode: null,
        comCode: null,
        makerId: 24,
        makerName: 'LAUFENN',
        itemName: 'LH42',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'H',
        tireXl: null,
        tireRunFlat: null,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: null,
        wetSpec: null,
        miniCarFlg: 0,
        minivanFlg: 0,
        suvFlg: 0,
        truckFlg: 0,
        importItem: 0,
        description: null,
        brandAlphaName: 'G FIT as-01',
        brandKanaName: 'ジーフィットエーエスゼロイチ',
        patternAlphaName: 'LH42',
        patternKanaName: 'エルエイチヨンニー',
        sale1: 6000,
        sale2: 5450,
        sale3: 5200,
        sale4: 5200,
        sale5: 6250,
        sale6: 5700,
        sale7: 5000,
        sale8: null,
        tireImage: null,
        shopStock: 0,
        warehouseStock: 1,
      },
      {
        jan: '8807622167423',
        makerItemCode: null,
        beelineCode: null,
        comCode: null,
        makerId: 23,
        makerName: 'NEXEN',
        itemName: 'CP672',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'H',
        tireXl: null,
        tireRunFlat: null,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: null,
        wetSpec: null,
        miniCarFlg: 0,
        minivanFlg: 0,
        suvFlg: 0,
        truckFlg: 0,
        importItem: null,
        description:
          '【コンフォート】\r\n○推奨車種『セダン、スポーツ、ミニバン、コンパクト、SUV』\r\n優れた静粛性（静か）、快適な乗り心地を実現。\r\n優れた操縦安定性（ハンドリング）、耐摩耗性（長持ち）を実現。',
        brandAlphaName: null,
        brandKanaName: null,
        patternAlphaName: 'CP672',
        patternKanaName: null,
        sale1: 5000,
        sale2: 4450,
        sale3: 4200,
        sale4: 4200,
        sale5: 5250,
        sale6: 4700,
        sale7: 3880,
        sale8: 0,
        tireImage: '/imgGetter/productImages/tire/tire_manual/CP672.jpg',
        shopStock: 0,
        warehouseStock: 1,
      },
      {
        jan: '8807622169601',
        makerItemCode: '12446NX',
        beelineCode: null,
        comCode: null,
        makerId: 23,
        makerName: 'NEXEN',
        itemName: 'N-FERA SU4',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'H',
        tireXl: 0,
        tireRunFlat: 0,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: null,
        wetSpec: null,
        miniCarFlg: 0,
        minivanFlg: 0,
        suvFlg: 0,
        truckFlg: 0,
        importItem: 0,
        description:
          '【ウルトラハイパフォーマンス】\r\n○推奨車種『クーペ、スポーツ、セダン』\r\n高い排水性とグリップを備えた高性能タイヤでオンロードで\r\n優れたドライビング性能を提供',
        brandAlphaName: 'N-FERA SU4',
        brandKanaName: 'エヌフェラエスユーフォー',
        patternAlphaName: 'N-FERA SU4',
        patternKanaName: 'エヌフェラエスユーフォー',
        sale1: 5000,
        sale2: 4450,
        sale3: 4200,
        sale4: 4200,
        sale5: 5250,
        sale6: 4700,
        sale7: 4000,
        sale8: null,
        tireImage: '/imgGetter/productImages/tire/tire_manual/N-FERA SU4.jpg',
        shopStock: 0,
        warehouseStock: 0,
      },
      {
        jan: '4523995028157',
        makerItemCode: '2755700',
        beelineCode: null,
        comCode: '1162',
        makerId: 8,
        makerName: 'PIRELLI',
        itemName: 'CINTURATO P6',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'V',
        tireXl: 0,
        tireRunFlat: 0,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: 'A',
        wetSpec: 'b',
        miniCarFlg: 0,
        minivanFlg: 0,
        suvFlg: 0,
        truckFlg: 0,
        importItem: 0,
        description:
          '【コンフォート】\r\n○推奨車種『セダン、ミニバン、コンパクト』\r\nバランスのとれたパフォーマンスでドライブに安心感を\r\n転がり抵抗を低減し、低燃費性能（エコ）を向上。\r\nノイズ改善し、静粛性が向上。快適な乗り心地を実現。\r\nウェット性能（雨に強い）＆ドライ性能（グリップ）が向上し安全性向上。\r\n■前型『CinturatoP1』　■ラべリング『A/b』低燃費タイヤ（エコタイヤ）',
        brandAlphaName: 'CINTURATO P6',
        brandKanaName: 'チントゥラートピーシックス',
        patternAlphaName: 'P6cint',
        patternKanaName: 'ピーシックスチント',
        sale1: 6250,
        sale2: 5700,
        sale3: 5450,
        sale4: 5450,
        sale5: 6500,
        sale6: 5950,
        sale7: 5260,
        sale8: 0,
        tireImage: '/imgGetter/productImages/tire/tire_manual/P6cint.jpg',
        shopStock: 0,
        warehouseStock: 0,
      },
      {
        jan: '8807622116254',
        makerItemCode: '11667RSUA',
        beelineCode: null,
        comCode: '0855',
        makerId: 31,
        makerName: 'ROADSTONE',
        itemName: 'N-BLUE ECO SH01',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'H',
        tireXl: 0,
        tireRunFlat: 0,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: null,
        wetSpec: null,
        miniCarFlg: 0,
        minivanFlg: 0,
        suvFlg: 0,
        truckFlg: 0,
        importItem: 0,
        description:
          '【ベーシック】\r\n○推奨車種『セダン、ミニバン、コンパクト、軽自動車、SUV（一部サイズ）』\r\n細かいトレッドデザインで排水性が高く、ウェット性能（雨に強い）に配慮。濡れた路面でも高い制動力と、操縦安定性を発揮。\r\n低燃費性能にも配慮。\r\n■ラべリング『A/c～B/c』',
        brandAlphaName: null,
        brandKanaName: null,
        patternAlphaName: 'SH01',
        patternKanaName: null,
        sale1: 5000,
        sale2: 4450,
        sale3: 4200,
        sale4: 4200,
        sale5: 5250,
        sale6: 4700,
        sale7: 4000,
        sale8: 0,
        tireImage: '/imgGetter/productImages/tire/tire_manual/SH01.jpg',
        shopStock: 0,
        warehouseStock: 1,
      },
      {
        jan: '4981910778585',
        makerItemCode: '13143544',
        beelineCode: null,
        comCode: null,
        makerId: 11,
        makerName: 'TOYO',
        itemName: 'NE03+',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'H',
        tireXl: null,
        tireRunFlat: null,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: 'A',
        wetSpec: 'b',
        miniCarFlg: 0,
        minivanFlg: 0,
        suvFlg: 0,
        truckFlg: 0,
        importItem: 0,
        description:
          '【ベーシック低燃費】\r\n○推奨車種『セダン、ミニバン、コンパクト、軽自動車』\r\nウェット性能にさらなる磨きをかけたスタンダード低燃費タイヤ\r\nウェット性能（雨に強い）が向上。\r\n優れた耐摩耗性（長持ち）と低燃費性能（エコ）を両立。\r\n■前型『ナノエナジー3』　■ラべリング『A/b』低燃費タイヤ（エコタイヤ）',
        brandAlphaName: 'NANOENERGY 3+',
        brandKanaName: 'ナノエナジースリープラス',
        patternAlphaName: 'NE 3+',
        patternKanaName: 'エヌイースリープラス',
        sale1: 9250,
        sale2: 8750,
        sale3: 9250,
        sale4: 8750,
        sale5: 9500,
        sale6: 9000,
        sale7: 8750,
        sale8: null,
        tireImage: '/imgGetter/productImages/tire/tire_manual/NE 3＋.jpg',
        shopStock: 0,
        warehouseStock: 1,
      },
      {
        jan: '4981910785804',
        makerItemCode: '13143641',
        beelineCode: null,
        comCode: null,
        makerId: 11,
        makerName: 'TOYO',
        itemName: 'TRANPATH ML',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'H',
        tireXl: null,
        tireRunFlat: null,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: 'AA',
        wetSpec: 'b',
        miniCarFlg: 0,
        minivanFlg: 1,
        suvFlg: 0,
        truckFlg: 0,
        importItem: 0,
        description:
          '【ミニバン】\r\n○推奨車種『ミニバン』\r\nミニバンの走りに驚きの低燃費を\r\nミニバン特有のフラつきを抑え、操縦安定性（ハンドリング）の向上。\r\nウェット性能を維持しながらも、優れた低燃費性能（エコ）を実現。\r\n耐偏摩耗性・耐摩耗性（長持ち）を実現。\r\n■ラべリング『AA/ｂ』低燃費タイヤ（エコタイヤ）',
        brandAlphaName: 'TRANPATH ML',
        brandKanaName: 'トランパスエムエル',
        patternAlphaName: 'TRA-ML',
        patternKanaName: 'ティーアールアーエムエル',
        sale1: 8500,
        sale2: 8000,
        sale3: 8500,
        sale4: 8000,
        sale5: 8750,
        sale6: 8250,
        sale7: 8000,
        sale8: null,
        tireImage: '/imgGetter/productImages/tire/tire_manual/TRA-ML.jpg',
        shopStock: 0,
        warehouseStock: 1,
      },
      {
        jan: '4981910796848',
        makerItemCode: '13143757',
        beelineCode: null,
        comCode: null,
        makerId: 11,
        makerName: 'TOYO',
        itemName: 'SD-7',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'H',
        tireXl: null,
        tireRunFlat: null,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: 'A',
        wetSpec: 'c',
        miniCarFlg: 0,
        minivanFlg: 0,
        suvFlg: 0,
        truckFlg: 0,
        importItem: 0,
        description:
          '【ベーシック】\r\n○推奨車種『セダン、ミニバン』\r\n基本性能にロングライフを加えた新スタンダード低燃費タイヤ\r\n高い耐摩耗性（長持ち）と低燃費性能（エコ）、ウェット性能（雨に強い）を融合。\r\nバランスのとれた基本性能。\r\n■ラべリング『A/c』低燃費タイヤ（エコタイヤ）',
        brandAlphaName: 'SD-7',
        brandKanaName: 'エスディーセブン',
        patternAlphaName: 'SD7',
        patternKanaName: 'エスディーナナ',
        sale1: 8600,
        sale2: 8100,
        sale3: 8600,
        sale4: 8100,
        sale5: 8850,
        sale6: 8350,
        sale7: 8100,
        sale8: null,
        tireImage: '/imgGetter/productImages/tire/tire_manual/SD7.jpg',
        shopStock: 0,
        warehouseStock: 1,
      },
      {
        jan: '4968814831127',
        makerItemCode: 'F6512',
        beelineCode: null,
        comCode: null,
        makerId: 1,
        makerName: 'YOKOHAMA',
        itemName: 'ES31',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'S',
        tireXl: null,
        tireRunFlat: null,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: 'A',
        wetSpec: 'c',
        miniCarFlg: 0,
        minivanFlg: 0,
        suvFlg: 0,
        truckFlg: 0,
        importItem: 0,
        description: null,
        brandAlphaName: 'ECOS ES31',
        brandKanaName: 'エコス　イーエスサンイチ',
        patternAlphaName: 'ES31',
        patternKanaName: 'イーエスサンイチ',
        sale1: 9250,
        sale2: 8700,
        sale3: 8450,
        sale4: 8450,
        sale5: 9500,
        sale6: 8950,
        sale7: 8450,
        sale8: 0,
        tireImage: '/imgGetter/productImages/tire/tire_manual/ES31.jpg',
        shopStock: 0,
        warehouseStock: 1,
      },
      {
        jan: '4968814859244',
        makerItemCode: 'F8320',
        beelineCode: null,
        comCode: '0184',
        makerId: 1,
        makerName: 'YOKOHAMA',
        itemName: 'AE01F',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'H',
        tireXl: null,
        tireRunFlat: null,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: 'AAA',
        wetSpec: 'c',
        miniCarFlg: 0,
        minivanFlg: 0,
        suvFlg: 0,
        truckFlg: 0,
        importItem: 0,
        description: null,
        brandAlphaName: 'BluEarth AE-01F',
        brandKanaName: 'ブルーアース　エーイーゼロイチエフ',
        patternAlphaName: 'AE01F',
        patternKanaName: 'エーイーゼロイチエフ',
        sale1: 8750,
        sale2: 8200,
        sale3: 7950,
        sale4: 7950,
        sale5: 9000,
        sale6: 8450,
        sale7: 7850,
        sale8: 0,
        tireImage: '/imgGetter/productImages/tire/tire_manual/AE01F.jpg',
        shopStock: 0,
        warehouseStock: 0,
      },
      {
        jan: '4968814875367',
        makerItemCode: 'F9345',
        beelineCode: null,
        comCode: '0179',
        makerId: 1,
        makerName: 'YOKOHAMA',
        itemName: 'RV02',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'H',
        tireXl: null,
        tireRunFlat: null,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: 'A',
        wetSpec: 'a',
        miniCarFlg: 0,
        minivanFlg: 1,
        suvFlg: 1,
        truckFlg: 0,
        importItem: 0,
        description: null,
        brandAlphaName: 'BluEarth RV-02',
        brandKanaName: 'ブルーアース　アールブイゼロニ',
        patternAlphaName: 'RV02',
        patternKanaName: 'アールブイゼロニ',
        sale1: 9500,
        sale2: 8950,
        sale3: 8700,
        sale4: 8700,
        sale5: 9750,
        sale6: 9200,
        sale7: 8700,
        sale8: 0,
        tireImage: '/imgGetter/productImages/tire/tire_manual/RV02.jpg',
        shopStock: 0,
        warehouseStock: 1,
      },
      {
        jan: null,
        makerItemCode: null,
        beelineCode: '0804319565159',
        comCode: '1486',
        makerId: 1,
        makerName: 'YOKOHAMA',
        itemName: 'V552(逆輸入)',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'H',
        tireXl: 0,
        tireRunFlat: 0,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: null,
        wetSpec: null,
        miniCarFlg: 0,
        minivanFlg: 0,
        suvFlg: 0,
        truckFlg: 0,
        importItem: 2,
        description:
          '【プレミアムコンフォート】\r\n○推奨車種『セダン、ミニバン、コンパクト、軽自動車』\r\n目指したものはかつてない静粛性\r\n非対称トレッドパターンを採用。IN側は細かいブロックを配置しノイズを抑制。高い静粛性（静か）を実現。更に排水性も向上し、優れたウェット性能（雨に強い）を実現させた。\r\nセンター部に配置した2本のストリートリブが操縦安定性（ハンドリング）を高め、晴れた日も雨の日も安心感のあるドライビングを実現。\r\n■前型『V551』　■ラべリング『A/a～A/b』低燃費タイヤ（エコタイヤ）',
        brandAlphaName: null,
        brandKanaName: null,
        patternAlphaName: 'V552',
        patternKanaName: null,
        sale1: 10750,
        sale2: 10200,
        sale3: 9950,
        sale4: 9950,
        sale5: 11000,
        sale6: 10450,
        sale7: 9820,
        sale8: null,
        tireImage: '/imgGetter/productImages/tire/tire_manual/V552.jpg',
        shopStock: 0,
        warehouseStock: 0,
      },
      {
        jan: null,
        makerItemCode: null,
        beelineCode: 'BL00000003533',
        comCode: '1400',
        makerId: 1,
        makerName: 'YOKOHAMA',
        itemName: 'AE51(逆輸入)',
        size: '195/65R15',
        tireLi: '91',
        tireSr: 'H',
        tireXl: 0,
        tireRunFlat: 0,
        allSeasonsFlg: 0,
        wtrFlg: 0,
        efficientSpec: null,
        wetSpec: null,
        miniCarFlg: 0,
        minivanFlg: 0,
        suvFlg: 0,
        truckFlg: 0,
        importItem: 2,
        description:
          '【コンフォート】\r\n○推奨車種『セダン、コンパクト、軽自動車』\r\n高い操縦安定性と低燃費性能を融合したグランドツーリングタイヤ\r\n力強い走りを生む、高剛性・非対称パターンでイン側は乗り心地、アウト側は操縦安定性（ハンドリング）を重視。\r\nふらつきを低減し、より快適な乗り心地を実現。\r\nグリップ性能を損なうことなく、低燃費性能（エコ）を向上。\r\n全サイズで最高グレードのウェット性能（雨に強い）「a」を獲得。\r\n■前型『AE50』　■ラべリング『AA/a～A/a』低燃費タイヤ（エコタイヤ）',
        brandAlphaName: null,
        brandKanaName: null,
        patternAlphaName: 'AE51',
        patternKanaName: null,
        sale1: 9250,
        sale2: 8700,
        sale3: 8450,
        sale4: 8450,
        sale5: 9500,
        sale6: 8950,
        sale7: 8130,
        sale8: null,
        tireImage: '/imgGetter/productImages/tire/tire_manual/AE51.jpg',
        shopStock: 0,
        warehouseStock: 1,
      },
    ],
    messages: [],
  },
}
