import Vue from 'vue'

// As a plugin
import VueMask from 'v-mask'
// Or as a directive
import { VueMaskDirective } from 'v-mask'
// Or only as a filter
import { VueMaskFilter } from 'v-mask'

Vue.use(VueMask)
Vue.directive('mask', VueMaskDirective)
Vue.filter('VMask', VueMaskFilter)
