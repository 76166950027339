import { actionTree, getterTree, mutationTree } from 'typed-vuex'
import { getClient } from '~/graphql/client'
import { PostCodeInput } from '~/graphql/generated/types'
import { logger } from '~/utils/logger'

const defaultState = () => {
  return {}
}

export const state = () => defaultState()

export const getters = getterTree(state, {})

export const mutations = mutationTree(state, {
  clear(state) {
    Object.assign(state, defaultState())
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    // 郵便番号検索	Query	postCode
    async postCode(_, input: PostCodeInput) {
      logger.info('郵便番号検索', { input })
      return await getClient()
        .postCode({
          input,
        })
        .then((data) => data.postCode)
    },
  }
)

export type RootState = ReturnType<typeof state>
