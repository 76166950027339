import { actionTree, getterTree, mutationTree } from 'typed-vuex'
import { getClient } from '~/graphql/client'
import {
  IntegrateUserInput,
  LoginInput,
  RegisterUserInput,
  UpdateUserInput,
  VerifyUserQrTokenInput,
  CreateEnqueteAnswerInput,
  CustomerInfoAttributesFragment,
  PrefectureInput,
} from '~/graphql/generated/types'
import { logger } from '~/utils/logger'

const defaultState = () => {
  return {
    _customerInfo: null as CustomerInfoAttributesFragment | null,
    _prefecture: null as string | null,
  }
}

export const state = () => defaultState()

export const getters = getterTree(state, {
  customerInfo: (state) => state._customerInfo,
  prefecture: (state) => state._prefecture,
})

export const mutations = mutationTree(state, {
  setCustomerInfo(state, val: CustomerInfoAttributesFragment) {
    state._customerInfo = val
  },
  setPrefecture(state, val: string) {
    state._prefecture = val
  },
  clear(state) {
    Object.assign(state, defaultState())
  },
})

export const actions = actionTree(
  { state, getters, mutations },
  {
    // 顧客登録	Mutation	registerUser
    async registerUser(_, input: RegisterUserInput) {
      logger.info('顧客登録', { input })
      return await getClient()
        .registerUser({
          input,
        })
        .then((data) => data.registerUser)
    },

    // 顧客連携QR検証	Mutation	verifyUserQRToken
    async verifyUserQRToken(_, input: VerifyUserQrTokenInput) {
      logger.info('顧客連携QR検証', { input })
      return await getClient()
        .verifyUserQRToken({
          input,
        })
        .then((data) => data.verifyUserQRToken)
    },

    // 顧客情報連携	Mutation	integrateUser
    async integrateUser(_, input: IntegrateUserInput) {
      logger.info('顧客情報連携', { input })
      return await getClient()
        .integrateUser({
          input,
        })
        .then((data) => data.integrateUser)
    },
    // 顧客情報取得	Query	user
    async user({ getters, commit }) {
      logger.info('顧客情報取得')
      if (getters.customerInfo) {
        logger.debug('顧客情報のキャッシュを使用')
        return getters.customerInfo
      }

      const customerInfo = await getClient()
        .user()
        .then((data) => data.user)

      commit('setCustomerInfo', customerInfo)

      return customerInfo
    },
    // 顧客情報更新	Mutation	updateUser
    async updateUser(_, input: UpdateUserInput) {
      logger.info('顧客情報更新', { input })
      return await getClient()
        .updateUser({
          input,
        })
        .then((data) => data.updateUser)
    },
    // 連携解除	Mutation	unsubscribe
    async unsubscribe() {
      logger.info('連携解除')
      return await getClient()
        .unsubscribe()
        .then((data) => data.unsubscribe)
    },
    // 認証	Mutation	authenticate
    async authenticate(_, input: LoginInput) {
      logger.info('認証', { input })
      return await getClient({ asGuest: true })
        .authenticate({
          input,
        })
        .then((data) => data.authenticate)
    },

    // 顧客アンケート
    async createEnqueteAnswer(_, input: CreateEnqueteAnswerInput) {
      logger.info('アンケート登録', { input })
      return await getClient()
        .createEnqueteAnswer({ input })
        .then((data) => data.createEnqueteAnswer)
    },

    // 位置情報取得
    async getPrefecture({ commit }, input: PrefectureInput) {
      logger.info('位置情報から都道府県を取得', { input })
      const { prefecture } = await getClient()
        .prefecture({ input })
        .then((data) => data.prefecture)

      commit('setPrefecture', prefecture || '')

      return { prefecture }
    },
  }
)

export type RootState = ReturnType<typeof state>
