
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    heading: {
      type: String,
      required: false,
      default: '',
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
  },
})
