import { actionTree, getterTree, mutationTree } from 'typed-vuex'
import {
  WorkingNameInfoAttributesFragment,
  StoreInfo,
  RegisterUserInput,
  CarInfoAttributesFragment,
  QuotationInfoAttributesFragment,
} from '../graphql/generated/types'

export type UserReservation = {
  scheduleId?: number
  date: string
  dateOnly?: string
  startTime: string
  store?: string
  address: string
  tel?: string
  goods?: string
  jan?: string
  shopId?: number
  quantity?: number
  storeUrl?: string
  workingTime?: number
  amount?: number
  workingId?: number
}

export type BringingParams = {
  bringingInTireSize: string
  bringingInQuantity: number
}

const defaultState = () => {
  return {
    _work: {} as WorkingNameInfoAttributesFragment,
    _car: {} as CarInfoAttributesFragment,
    _quotation: {} as QuotationInfoAttributesFragment | null,
    _shop: {} as StoreInfo,
    _user: {
      // 顧客区分
      customerKind: 0,
    } as RegisterUserInput,
    _date: { date: '', time: '' },
    _userReservation: {} as UserReservation,
    _bringingParam: {} as BringingParams | null,
    _granteeTotalAmount: 0,
  }
}

export const state = () => defaultState()

export const getters = getterTree(state, {
  work: (state) => state._work,
  car: (state) => state._car,
  quotation: (state) => state._quotation,
  shop: (state) => state._shop,
  user: (state) => state._user,
  date: (state) => state._date,
  userReservation: (state) => state._userReservation,
  bringingParam: (state) => state._bringingParam,
  granteeTotalAmount: (state) => state._granteeTotalAmount,
})

export const mutations = mutationTree(state, {
  clear(state) {
    Object.assign(state, defaultState())
  },
  clearQuotation(state) {
    state._quotation = null
  },
  clearBringingParam(state) {
    state._bringingParam = null
  },
  setWork(state, input: WorkingNameInfoAttributesFragment) {
    state._work = input
  },
  setCar(state, input: CarInfoAttributesFragment | string) {
    if (typeof input === 'string') {
      state._car = { ...getters.car, carName: input }
    } else {
      state._car = input
    }
  },
  setQuotation(state, input: QuotationInfoAttributesFragment) {
    state._quotation = input
  },
  setShop(state, input: StoreInfo) {
    state._shop = input
  },
  setUser(state, inputs: RegisterUserInput) {
    state._user = inputs
  },
  setDate(state, inputs: any) {
    state._date = inputs
  },
  setUserReservation(state, inputs: UserReservation) {
    state._userReservation = inputs
  },
  setBringingParam(
    state,
    inputs: { bringingInTireSize: string; bringingInQuantity: number }
  ) {
    state._bringingParam = inputs
  },
  setGranteeTotalAmount(state, inputs: number) {
    state._granteeTotalAmount = inputs
  },
})

export const actions = actionTree({ state, getters, mutations }, {})

export type RootState = ReturnType<typeof state>
