import _ from 'lodash'
import { getterTree, mutationTree } from 'typed-vuex'
import { QuotationInfoAttributesFragment } from '~/graphql/generated/types'

type vehicleType = {
  vehicleMakerName: ''
  vehicleTypeName: ''
}

type guarantee = {
  quantity: 0
  amount: 0
  beelineCode: ''
}
const defaultState = () => {
  return {
    _shopId: null as number | null,
    _quotations: [] as QuotationInfoAttributesFragment[],
    _adCode: '',
    _bpsToken: '',
    _vehicle: null as vehicleType | null,
    _guarantee: null as guarantee | null,
    // 見積もり詳細->タイヤ見積完了->ピット/来店予約と遷移した時に
    // すでに見積もりしたことのある商品だと別の見積もりが最後に取得できる可能性があるため
    // 一時的に見積もりを保持するための値
    _temporaryQuotationForPitReservation:
      null as QuotationInfoAttributesFragment | null,
  }
}

export const state = () => defaultState()

export const getters = getterTree(state, {
  shopId: (state) => state._shopId,
  quotations: (state) => state._quotations,
  adCode: (state) => state._adCode,
  bpsToken: (state) => state._bpsToken,
  vehicle: (state) => state._vehicle,
  guarantee: (state) => state._guarantee,
  temporaryQuotationForPitReservation: (state) =>
    state._temporaryQuotationForPitReservation,
})

export const mutations = mutationTree(state, {
  setShopId(state, val: number) {
    state._shopId = val
  },
  addQuotation(state, val: QuotationInfoAttributesFragment) {
    state._quotations = [...state._quotations, val]
  },
  removeQuotation(state, quotationIds: number) {
    state._quotations = _.filter(
      state._quotations,
      (item) => item.quatationId !== quotationIds
    )
  },
  clearQuotations(state) {
    state._quotations = []
  },
  setAdCode(state, val: string) {
    state._adCode = val
  },
  clearAdCode(state) {
    state._adCode = ''
  },
  setBpsToken(state, val: string) {
    state._bpsToken = val
  },
  clearBpsToken(state) {
    state._bpsToken = ''
  },
  clear(state) {
    Object.assign(state, defaultState())
  },
  setVehicle(state, val: any) {
    state._vehicle = val
  },
  clearVehicle(state) {
    state._vehicle = null
  },
  setGuarantee(state, val: any) {
    state._guarantee = val
  },
  clearGuarantee(state) {
    state._guarantee = null
  },
  setTemporaryQuotationForPitReservation(state, val: any) {
    state._temporaryQuotationForPitReservation = val
  },
  clearTemporaryQuotationForPitReservation(state) {
    state._temporaryQuotationForPitReservation = null
  },
})

export type RootState = ReturnType<typeof state>
